<template>
  <div id="app">
    <header class="header">
      <div class="header__top">
        <div class="grid-container">
          <div class="grid-x grid-padding-x">
            <div class="small-12 cell">
              <img src="./assets/logo.svg" alt="logo">
            </div>
          </div>
        </div>
      </div>
      <div class="header__bottom">
        <img src="./assets/header.png" srcset="./assets/header@2x.png 2x" class="header__img" width="1152" alt="On target">
        <div class="header__content text-right">
          <h1 class="header__title">Bem-vindo ao universo da medicina de precisão.</h1>
          <h2 class="header__subtitle">Aqui, o tratamento do cancro é mesmo</h2>
          <img src="./assets/logo-white.svg" class="header__content-img" width="320" alt="On target">
        </div>
      </div>
    </header>
    <main>
      <section class="area">
        <div class="grid-container">
          <div class="grid-x grid-padding-x align-center">
            <div class="small-12 large-6 cell">
              <div class="area__icon">
                <img src="./assets/icons/area.svg" alt="Área de interesse">
              </div>
              <h2 class="section-title section-title--blue">Área de interesse</h2>
              <p class="section-text">
                Este é o espaço onde vai poder saber mais sobre o conceito de medicina de precisão, enquanto avalia a eficácia e a segurança dos tratamentos com indicação em tumores com fusão do gene NTRK.<br><br>
                <strong>Selecione os temas que mais lhe interessam</strong> e navegue por este universo fascinante. 
              </p>
            </div>
            <div class="small-12 large-4 cell text-center">
              <img src="./assets/img-body.svg" alt="body">
            </div>
          </div>
        </div>
        <div class="grid-container area__row">
          <div class="grid-x grid-padding-x align-center">
            <div class="small-12 medium-11 large-10 cell">
              <div class="area__buttons">
                <div class="area__btn" v-for="area in areas" :key="area.id">
                  <area-button :btn="area" @area-select="selectedArea"></area-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="contact">
        <div class="grid-container">
          <div class="grid-x grid-padding-x align-center">
            <div class="small-12 large-10 cell text-center">
              <img src="./assets/icons/contact.svg" alt="+ SOBRE FUSÃO NTRK?">
              <h2 class="section-title section-title--purple contact__title">+ SOBRE FUSÃO NTRK?</h2>
            </div>
          </div>
        </div>
        <div class="grid-container">
          <div class="grid-x grid-padding-x align-center">
            <div class="small-12 large-10 cell">
              <form-component :areas="areas" :mainArea="mainArea" :status=status @update-status=updateStatus></form-component>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer class="footer">
      <div class="grid-container">
          <div class="grid-x grid-padding-x">
            <div class="small-12 medium-2 cell text-center medium-text-left">
              <img src="./assets/logo-bayer.svg" alt="logo Bayer">
            </div>
            <div class="small-12 medium-10 cell text-center medium-text-left">
              <ul class="footer-menu">
                <li class="footer-menu__item">Copyright © Bayer AG </li>
                <li class="footer-menu__item"><a href="">Termos de Utilização</a></li>
                <li class="footer-menu__item"><a href="">Política de Privacidade</a></li>
              </ul>
              <p class="footer__text text-center medium-text-right">BAYER PORTUGAL, Lda. R. Quinta do Pinheiro, n.º 5, 2794-003 Carnaxide, Lisboa - Oeiras NIF: 500 043 256 - Tel.: 214 172 121 - www.bayer.pt </p>
              <p class="footer__text footer__text--small text-center medium-text-right">MA-LAR-PT-0027-1 02/2022</p>
            </div>
          </div>
      </div>
    </footer>
  </div>
</template>

<script>
import AreaButton from './components/AreaButton.vue'
import FormComponent from './components/FormComponent.vue'


export default {
  name: 'App',
  components: {
    AreaButton,
    FormComponent
  },
  data () {
    return {
      mainArea: null,
      areas: [
        {
          id: 1,
          icon: require('./assets/icons/geral.svg'),
          text: 'Geral/agnóstico'
        },
        {
          id: 2,
          icon: require('./assets/icons/tumores.svg'),
          text: 'Tumores Cerebrais'
        },
        {
          id: 3,
          icon: require('./assets/icons/glandulas-salivares.svg'),
          text: 'Cancro das Glândulas Salivares'
        },
        {
          id: 4,
          icon: require('./assets/icons/tiroide.svg'),
          text: 'Cancro da Tiroide'
        },
        {
          id: 5,
          icon: require('./assets/icons/sarcomas.svg'),
          text: 'Sarcomas'
        },
        {
          id: 6,
          icon: require('./assets/icons/mama.svg'),
          text: 'Cancro da Mama'
        },
        {
          id: 7,
          icon: require('./assets/icons/pulmao.svg'),
          text: 'Cancro do Pulmão'
        },
        {
          id: 8,
          icon: require('./assets/icons/colorretal.svg'),
          text: 'Cancro Colorretal'
        },
        {
          id: 9,
          icon: require('./assets/icons/pediatricos.svg'),
          text: 'Tumores Pediátricos'
        }
      ],
      status: 0
    }
  },
  methods: {
    selectedArea (event) {
      this.mainArea = event
    },
    updateStatus (e) {
      this.status = e
      window.history.pushState('', '', '/')
    }
  },
  created () {
    var param1 = 'status'
    var regex1 = new RegExp('[\\?&]' + param1 + '=([^&#]*)')
    var results1 = regex1.exec(window.location.search)
    this.status = results1 === null ? 0 : decodeURIComponent(results1[1].replace(/\+/g, ' '))
  }
}
</script>

<style lang="scss">
  @import "./styles/app.scss";
</style>
