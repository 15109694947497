<template>
  <div>
      <a href="#ntrk" class="btn btn--secondary" :id="'btn-area-'+btn.id" @click="selectedArea(btn.text)"><img :src="btn.icon" />{{ btn.text }}</a>
  </div>
</template>

<script>
export default {
  props: ['btn'],
  data () {
      return {
      }
  },
  methods: {
    selectedArea (text) {
      this.$emit("area-select", text)
    }
  },
  created () {
  }
}
</script>
