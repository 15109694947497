<template>
    <div>
        <form ref="form" id="ntrk" class="form" @submit.prevent="" data-smk-form action="https://caetsu.smark.io/api.php/directlead/7462d9d79d7f150c4c06e6aae85157499f4ed23e" method="post">
            <input type="hidden" name="redirect_ok" value="https://vitrakvi.caetsudigital.com/?status=1">
            <input type="hidden" name="redirect_error" value="https://vitrakvi.caetsudigital.com/?status=0">
            <div class="form__group">
                <div class="form__input">
                    <label for="first_name" class="form__label">Nome*</label>
                    <input type="text" class="form__input-text" name="lead[first_name]" placeholder="Insira primeiro e último nome" v-model="form.first_name" v-validate="'required'" data-vv-as="Nome">
                    <span class="error" v-if="errors.first('lead[first_name]')">{{ errors.first('lead[first_name]') }}</span>
                </div>
                <div class="form__input">
                    <label class="form__label">Especialidade*</label>
                    <select ref="especialidade" name="extra[especialidade]" class="form__input-select" v-validate="'required'" v-model="form.especialidade" data-vv-as="Especialidade">
                        <option value="patologia">Patologia</option>
                        <option value="oncologia">Oncologia</option>
                        <option value="pediatria">Pediatria</option>
                    </select>
                    <span class="error" v-if="errors.first('extra[especialidade]')">{{ errors.first('extra[especialidade]') }}</span>
                </div>
               
            </div>
            <div class="form__group">
                <div class="form__input">
                    <label for="email" class="form__label">Email*</label>
                    <input type="text" class="form__input-text" name="lead[email]" placeholder="Email" v-model="form.email" v-validate="'required|email'" data-vv-as="Email">
                    <span class="error" v-if="errors.first('lead[email]')">{{ errors.first('lead[email]') }}</span>
                </div>
                <div class="form__input">
                    <label for="n_carteira_profissional" class="form__label">N.º Carteira Profissional*</label>
                    <input type="text" class="form__input-text" name="extra[n_carteira_profissional]" placeholder="N.º Carteira Profissional" v-model.number="form.n_carteira_profissional" v-validate="'required|max_value:99999999'" data-vv-as="N.º Carteira Profissional">
                    <span class="error" v-if="errors.first('extra[n_carteira_profissional]')">{{ errors.first('extra[n_carteira_profissional]') }}</span>
                </div>
            </div>
            <div class="form__group">
                <div class="form__input">
                    <label class="form__label">Área de interesse preferencial*</label>
                    <select name="extra[temafavorito]" class="form__input-select" v-validate="'required'" v-model="form.temafavorito" data-vv-as="Área de interesse">
                         <option :value="area.text" v-for="area in areas" :key="area.id">{{area.text}}</option>
                    </select>
                    <span class="error" v-if="errors.first('extra[temafavorito]')">{{ errors.first('extra[temafavorito]') }}</span>
                </div>
            </div>
            <div class="form__group">
                <div class="form__input">
                    <label class="form__label">Receber mais informações sobre outros tumores de fusão NTRK?*</label>
                    <div class="flex-container">
                        <div>
                            <input ref="recebermaisinformacao" type="radio" id="recebermaisinformacaoYes" name="extra[recebermaisinformacao]" v-model="form.recebermaisinformacao" value="yes" v-validate="'required'" data-vv-as="Receber mais informações">
                            <label for="recebermaisinformacaoYes">Sim</label>
                        </div>
                        <div>
                            <input type="radio" id="recebermaisinformacaoNo" name="extra[recebermaisinformacao]" v-model="form.recebermaisinformacao" value="no">
                            <label for="recebermaisinformacaoNo">Não</label>
                        </div>
                    </div>
                    <span class="error" v-if="errors.first('extra[recebermaisinformacao]')">{{ errors.first('extra[recebermaisinformacao]') }}</span>
                        
                </div>
            </div>
            <div class="form__group" v-if="form.recebermaisinformacao==='yes'">
                <div class="form__input">
                    <label class="form__label">Escolher outras áreas de interesse*</label>
                    <div class="form__input-select-container">
                        <div class="form__input-select form__input-select--half" v-for="area in areas" :key="area.id">
                            <input type="checkbox" :id="'temas_interesse' + area.id" name="extra[temas_interesse_temp]" v-validate="'required'" v-model="form.temas_interesse" :value="area.text">
                            <label :for="'temas_interesse' + area.id">{{area.text}}</label>
                        </div>
                    </div>
                    <span class="error" v-if="errors.first('extra[temas_interesse]')">{{ errors.first('extra[temas_interesse]') }}</span>
                    <input type="hidden" :value="convertToString" name="extra[temas_interesse]">
                </div>
            </div>
            <div class="form__group">
                <div class="form__input">
                    <div class="form__input-select-container">
                        <div class="form__input-select">
                            <input type="checkbox" id="CustomerConsent" name="extra[aceita_os_termos]"  v-model="form.CustomerConsent" value="yes">
                            <label for="CustomerConsent" class="form__label--small form__label--min">Autorizo a recolha e tratamento dos meus dados pessoais, para fins de envio de comunicação direta sobre as áreas em que manifestar interesse.<br><br>
                                <span class="form__label--small form__label--min">*Campos de preenchimento obrigatório.</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form__group">
                <div class="form__input text-left">
                    <button type="submit" id="btn-form-submit" class="btn btn--primary" @click="submitPreRequest" :disabled="btnDisable">Submeter</button>
                </div>
            </div>
        </form>
        <div class="modal" v-if="status ==='1?' ||  status ==='0?'">
            <div class="modal__content">
                <button @click="updateStatus">
                    <img src="../assets/icons/icon-close.svg" class="modal__icon" alt="">
                </button>
                <div class="modal__title" v-if="status ==='1?'">
                    As suas preferências foram submetidas com sucesso.
                </div>
                <div class="modal__title" v-if="status ==='0?'">
                    Erro na submissão.<br> Por tente novamente.
                </div>
                <div class="modal__text" v-if="status ==='1?'">
                    A partir de agora vamos mantê-lo atualizado sobre o universo da medicina de precisão e das fusões NTRK.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import pt from 'vee-validate/dist/locale/pt_PT'
import VeeValidate, { Validator } from 'vee-validate'
import VueTheMask from 'vue-the-mask'
import axios from 'axios'

Vue.prototype.$http = axios
Vue.use(VeeValidate)
Vue.use(VueTheMask)
Validator.localize('pt', pt)

export default {
    props: ['areas', 'mainArea', 'status'],
    data () {
        return {
            btnDisable: false,
            form: {
                first_name: null,
                especialidade: null,
                email: null,
                n_carteira_profissional: null,
                temafavorito: null,
                temas_interesse: [],
                recebermaisinformacao: null,
                CustomerConsent: null,
            }
        }
    },
    created () {
    },
    methods: {
        submitPreRequest: function () {
            this.btnDisable = true
            var _this = this
            this.$validator.validate().then(function (valid) {
                if (valid) {
                    console.log('valido')
                    _this.$refs.form.submit()
                }
            })
            this.btnDisable = false
        },
        updateStatus: function () {
            this.$emit("update-status", 0)
        }
    },
    computed: {
        convertToString() {
            return this.form.temas_interesse.join()
        } 
    },
    watch: {
        mainArea(newArea) {
            this.form.temafavorito = newArea
        },
    }
}
</script>
